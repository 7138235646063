import {Model} from "objectmodel";

/**
 * Product model
 * @class Product
 * @see module:api.products {@link module:api#products}
 * @property {number|string} [id] If not set, the enitty is not yet persisted
 * @property {string} title
 * @property {string} description
 * @property {string} price_display
 * @property {number} price
 * @property {string} unit
 * @property {string} sellUnit
 * @property {number} quantityStep
 * @property {string} slug
 * @property {null|Array.<Category>} [categories]
 * @property {null|Image} [image]
 * @property {null|Array.<Image>} [images]
 * @property {boolean} out_of_stock
 * @property {boolean} disable_online_shopping
 */
export class Seo extends Model({
    id: [Number,String,undefined],
    metaTitle: [String, null, undefined],
    metaDescription: [String, null, undefined],
    keywords: [String, null, undefined],
    metaRobots: [String, null, undefined],
    structuredData: [String, null, undefined],
    metaViewport: [String, null, undefined],
    canonicalURL: [String, null, undefined],
}) {


    toJSON() {
        return {...this};
    }
}
