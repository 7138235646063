import TimestampableModel from "@/src/models/TimestampableModel";

export default class AddressComponent extends TimestampableModel.extend({
    label: [String, undefined, null],
    name: [String],
    street: [String],
    complement: [String, null, undefined],
    postcode: [String],
    city: [String],
    country: [String],
    phone: [String],
}).defaultTo({
  label: null,
  name: '',
  phone: '',
  city: '',
  complement: '',
  street: '',
  postcode: '',
  country: 'France'
}) {
    constructor(args) {
        super(args);
    }
    toJSON() {
        return {...this};
    }
}
