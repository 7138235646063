import {Model} from "objectmodel";
import dayjs from "@/src/dayjs";

/**
 * @class TimestampableModel
 * @abstract
 * @property {String} created_at
 * @property {String} updated_at
 */
export default class TimestampableModel extends Model({
    createdAt: [String],
    updatedAt: [String],
}) {

    /**
     * @member
     * @return {dayjs.Dayjs}
     */
    get createdAt$() { return dayjs(this.created_at); }

    /**
     * @member
     * @return {dayjs.Dayjs}
     */
    get updatedAt$() { return dayjs(this.updated_at); }

    toJSON() {
        return {...this};
    }
}
