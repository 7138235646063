import OrderItem from "@/src/models/OrderItem";
import dayjs from "@/src/dayjs";
import {Any, ArrayModel, Model} from "objectmodel";
import TimestampableModel from "@/src/models/TimestampableModel";
import User from "@/src/models/User";
import AddressComponent from "@/src/models/AddressComponent";
import {useApi} from "@/src/api";



export default class Order extends TimestampableModel.extend({
    id: [String, Number, null, undefined],
    order_items: [ArrayModel(OrderItem), undefined],
    reference: [String, undefined],
    date_retrait: [String,null, undefined],
    validated_at: [String,null, undefined],
    log: [Any, Object],
    statut: ['cart','validated','paid','preparing','ready','withdrawn'],
    billing_address: [AddressComponent,Any],
    comment: [String, null, undefined],
}).defaultTo({
    id: null,
    order_items: [],
    reference: '',
    date_retrait: null,
    statut: 'cart',
    log: [],
    billing_address: null,
}) {

    constructor({id, order_items, date_retrait, statut, reference, log, created_at, updated_at}) {
        super({id, order_items, date_retrait, statut, reference, log, created_at, updated_at});
        if (!this.reference) {
            this.reference = `commande-${dayjs().format('YYYY-MM-DD')}`;
        }
        this.api = useApi();
    }

    get items() {
        return this.order_items.map((oi) => new OrderItem(oi))
    }

    /**
     * @member
     * @return {dayjs.Dayjs}
     */
    get dateRetrait$() { return dayjs(this.date_retrait); }
    get validatedAt$() { return dayjs(this.validated_at); }

    toDTO() {
        return {
            id: this.id,
            reference: this.reference,
            date_retrait: this.dateRetrait$.format(),
            statut: this.statut,
            log: this.log
        }
    }

    async save() {
        let savedOrder = await this.api.orders.save({
           ...this.toStrapi4UpdateObject(),
        });
        this.id = savedOrder.id; // Save the id if it's a creation
        this.billing_address.id = savedOrder.billing_address?.id || null;
        const updatedOrder = await this.api.orders.findOne({id: this.id}) // Get all attributes (update or create order do not return components)
        this.order_items = updatedOrder.order_items;
        return updatedOrder;
    }

    toStrapi4UpdateObject() {
        return {
            id: this.id,
            order_items: this.order_items.map(oi => oi.toStrapi4UpdateObject()),
            reference: this.reference,
            statut: this.statut,
            log: this.log,
            date_retrait: this.date_retrait,
            billing_address: this.billing_address,
            comment: this.comment
        };
    }


    toJSON() {
        return {...this};
    }
}
Order.createFromStrapi4 = function (obj) {
    return new Order({
        id: obj.id,
        ...obj.attributes,
        order_items: [...obj.attributes.order_items.map(oi => OrderItem.createFromStrapi4(oi))]

    });
}
