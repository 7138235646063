import { defineStore } from 'pinia'
import {useApi} from "~/src/api";
import {getAuthToken, isTokenValid, resetAuthToken} from "~/src/auth";
export const useStrapiUserStore = defineStore({
  id: 'strapiUser',
  state: () => ({
    token: null,
    user: null,
    orders: []
  }),
  getters: {
    isAuthenticated: state => {
      return Boolean(isTokenValid() && state.user)
    },
    addresses: state => state.user?.addresses || [],
    api: () => useApi()
  },
  actions: {
    async refreshMe() {
      try {
        this.user = await this.api.user.me();
      }
      catch (e) {
        console.warn('user cant be refreshed', e);
      }
    },
    /**
     * @param {object} authDetails
     * @param {string} authDetails.name
     * @param {string} authDetails.email
     * @param {string} authDetails.password
     * @returns {Promise<void>}
     */
    async register (authDetails) {
      this.user = await this.api.user.register({...authDetails})
      this.token = getAuthToken();
    },

    /**
     * @param authDetails
     * @param {string} authDetails.identifier
     * @param {string} authDetails.password
     * @returns {Promise<void>}
     */
    async login (authDetails) {
      // const {data} = await apolloClient.mutate({ mutation: LOGIN_USER, variables: { ...authDetails } })
      this.user = await this.api.user.login({...authDetails});
      this.token = getAuthToken();
    },

    /**
     * @param authDetails
     * @param {string} authDetails.identifier
     * @returns {Promise<void>}
     */
    async forgot (authDetails) {
      // const {data} = await apolloClient.mutate({ mutation: LOGIN_USER, variables: { ...authDetails } })
      this.user = await this.api.user.askForgotPasswordLink({...authDetails});
    },

    /**
     * @param authDetails
     * @param {string} authDetails.email
     * @param {string} [authDetails.username]
     * @param {object} [authDetails.context]
     * @returns {Promise<void>}
     */
    async passwordLessSendLink (authDetails) {
        // const {data} = await apolloClient.mutate({ mutation: LOGIN_USER, variables: { ...authDetails } })
      return await this.api.passwordLess.sendLink({...authDetails});
    },

    /**
     * @param authDetails
     * @param {string} authDetails.token
     * @returns {Promise<void>}
     */
    async passwordLessLogin (authDetails) {
      return await this.api.passwordLess.login({...authDetails});
    },


    async findOrders() {
      this.orders = await this.api.orders.find({});
      this.orders.sort((a, b) => b.id - a.id)
      return this.orders;
    },
    logout () {
      this.token = null;
      this.user = null;
      resetAuthToken();
    },
  }
})
