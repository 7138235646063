import {defineStore} from "pinia";
import OrderItem from "~/src/models/OrderItem";
import {roundPrice, fetchProduct, Product} from '~/src/models/Product';
import AddressComponent from "~/src/models/AddressComponent";
import dayjs from "dayjs";
import {isTokenValid} from "~/src/auth";
import {useApi} from "~/src/api";
import CartOrderIdNotFound from "~/src/errors/CartOrderIdNotFound";
import Order from "~/src/models/Order";
import CartAlreadyValidatedError from "~/src/errors/CartAlreadyValidatedError";
import {delay} from "~/src/functions/delay";
import {useProductsStore} from "~/stores/products.js";

/**
 * @namespace cart
 * @memberOf stores
 */

// import {dayjs} from 'dayjs/index.d.ts'
/**
 * @memberOf stores.cart
 * @typedef CartState
 * @property {Boolean} createOrUpdateInProgress
 * @property {Date} [lastUpdate]
 * @property {Date} [lastSend]
 * @property {String} [lastSendHash]
 * @property {String} [reference]
 * @property {Date} [dateRetrait]
 * @property {string[]} [log]
 * @property {dayjs.Dayjs} [lastUpdate$]
 * @property {dayjs.Dayjs} [lastSend$]
 * @property {dayjs.Dayjs} [dateRetrait$]
 * @property {number|string} [id]
 * @property {Array.<OderItem>} items
 * @property {Array.<OderItem>} cart Dynamic getter that ensure all object are casted to {@link OrderItem}
 * @property {object|AddressComponent} billingAddress
 *
 */

/**
 * @memberOf stores.cart
 * @callback
 * @param state
 * @returns {function(*): OrderItem}
 */
export const getOrderItemByProductId = state => productId => state.items.find(orderItem => orderItem?.productId === productId);
export const getOrderItemByReference = state => ref => state.items.find(orderItem => orderItem?.reference === ref);
/**
 * @memberOf stores.cart
 * @constructor CartState
 * @type {StoreDefinition<"cart",CartState>}
 */
export const useCartStore = defineStore('cart', {
    /**
     * @return {CartState}
     */
    state: () => {
        return {
            createOrUpdateInProgress: false,
            reference: null,
            items: [],
            id: null, // the remote id of the cart
            lastSend: null,
            lastSendHash: null,
            dateRetrait: null,
            log: [],
            billingAddressName: null,
            billingAddressStreet: null,
            billingAddressComplement: null,
            billingAddressPostcode: null,
            billingAddressCity: null,
            billingAddressCountry: 'France',
            billingAddressPhone: null,
        }
    },
    getters: {
        billingAddress: (state) => {
            return new AddressComponent({
                name: state.billingAddressName,
                street: state.billingAddressStreet,
                complement: state.billingAddressComplement,
                postcode: state.billingAddressPostcode,
                city: state.billingAddressCity,
                country: state.billingAddressCountry,
                phone: state.billingAddressPhone
            });
        },
        lastSend$: state => dayjs(state.lastSend),
        dateRetrait$: state => dayjs(state.dateRetrait),
        logs: (state) => {
            const logs = [...(new Set(state.log))].map(l => l.split(' : '));
            logs.sort((a, b) => {
                return (new Date(b[0])) - (new Date(a[0]));
            })
            return logs.map((l) => {
                return l.join(' : ');
            });
        },
        cart: state => state.items.map(si => new OrderItem(si || {})),
        productCount: state => id => Number(getOrderItemByProductId(state)(id)?.quantity || 0),
        cartItemsCount: state => state.items.length || 0,
        orderItemByProductId: getOrderItemByProductId,
        total: state => roundPrice(state.items.map(p => (new OrderItem(p)).getTotal()).reduce((total, price) => total+price, 0))
    },
    actions: {
        /**
         * Generate a sha256 hash of the cart
         * @see https://developer.mozilla.org/fr/docs/Web/API/SubtleCrypto/digest
         * @param {CartState} state
         * @return {Promise<string>}
         */
        async generateCartHash()  {

            const cartString = JSON.stringify({
                order_items: [...this.items.map(oi => oi.toStrapi4UpdateObject())],
                date_retrait: this.dateRetrait,
                billing_address: this.billingAddress,
                id: this.id
            })
            const msgUint8 = new TextEncoder().encode(cartString);
            const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8);           // fait le condensé
            const hashArray = Array.from(new Uint8Array(hashBuffer));                     // convertit le buffer en tableau d'octet
            const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join(''); // convertit le tableau en chaîne hexadélimale
            return hashHex;
        },
        /**
         * Save the cart to the server
         * @returns {Promise<Order>} the order created or saved
         */

        addLog(msg) {
            if (!this.log) this.log = [];
            this.log.push(`${(new Date()).toISOString()} : ${msg}`);
        },
        async saveCartRemotely() {
            const api = useApi();
            const currentOrderHash = await this.generateCartHash();
            if (this.createOrUpdateInProgress) return null;
            if (currentOrderHash === this.lastSendHash) return null;
            this.createOrUpdateInProgress = true;

            /** @type {Order} */
            let order = null;
            try {
                if (isTokenValid()) {
                    if (this.id) {
                        try {
                            order = await api.orders.findOne({id: this.id});
                        }
                        catch (e) {
                            console.log("La commande du panier actuel n'existe pas", e, this);
                            console.warn(e);
                            throw new CartOrderIdNotFound({id: this.id});
                        }
                    }

                    if (!order) {
                        order = new Order({
                            statut: 'cart',
                            log: [
                                `${dayjs().format()} : Création/Sauvegarde de la commande`
                            ],
                        });
                    }

                    if (order.statut !== 'cart') {
                        throw new CartAlreadyValidatedError(order);
                    }
                    else {
                        // this.addLog(`Envoie au serveur...`);
                        if (this.reference) {
                            order.reference = this.reference;
                        }

                        this.reference = order.reference;
                        order.billing_address = this.billingAddress;
                        order.order_items = [...this.items];
                        order.log = [...new Set([...this.log, ...order.log])];
                        order.date_retrait = this.dateRetrait;
                        // order.log = [];
                        try {
                            const updatedOrder = await order.save();
                            console.log('order saved', updatedOrder.reference);
                            this.lastSend = (new Date()).toISOString();
                            this.lastSendHash = currentOrderHash;
                            this.$patch(state => {
                                if (state.id !== updatedOrder.id) {
                                    console.log('update the cart order id', updatedOrder.id)
                                    state.id = updatedOrder.id;
                                }
                                // Add the id of each order-items component to allow update
                                state.log = [...new Set([...updatedOrder.log])];
                                updatedOrder.order_items.forEach((oi)=> {
                                    const orderItemFromCart = state.items.find(o => o.id === oi.id || (oi.getReference() === o.getReference() && oi.productId === o.productId));
                                    orderItemFromCart.id = oi.id
                                })
                            })
                            // this.addLog(`Mise à jour serveur réussie`);
                        }
                        catch (e) {
                            // this.addLog(`Mise à jour serveur en ERREUR`);

                            throw e;
                        }
                    }

                }
            }
            catch (e) {
                if (e instanceof CartAlreadyValidatedError) {
                    console.error('TODO : demander au user si il souhaite créer une nouvelle commande');
                    throw e;
                }
                console.warn("Les éléments du panier n'ont pas pû être envoyé au serveur", e);
            }
            // await delay(2000); // For dev only
            this.createOrUpdateInProgress = false
            return order;
        },
        async updateProduct({id}) {
            try {
                const productFetched = await fetchProduct(id);
                const orderItem = getOrderItemByProductId(this)(id);
                if (orderItem) {
                    // console.log('update product in saleitem', orderItem);
                    OrderItem.prototype.updateProductInformations.apply(orderItem, [productFetched]);
                    await this.setItem({productId: id, quantity: orderItem.quantity})
                    this.addLog(`Mise à jour des infos produit ${orderItem.getReference()} (#${productFetched.id})`);

                }
            } catch (e) {
                console.error(e)
            }
        },
        setCart(productList) {
            this.items.splice(0, this.items.length) // reset cart items
            productList.forEach(orderItem => this.items.push(new OrderItem(orderItem)));
        },
        removeItem(orderItem) {
            return this.$patch(async (state) => {
                const orderItemIndex = state.items.findIndex(oi => oi.id === orderItem.id || (orderItem.reference === oi.reference && orderItem.productId === oi.productId));
                console.log('removeItem', orderItem, orderItemIndex)
                if (orderItemIndex > -1) {
                    state.items.splice(orderItemIndex, 1);
                    this.addLog(`Suppression du produit ${orderItem.getReference()} (qte: ${orderItem.quantity})`);
                }
                if (state.items.length === 0) {
                    state.log = [];
                    state.reference = (new Order({statut: 'cart'})).reference;
                }
            })
        },
        removeItemByProductId(productId) {
            return this.$patch(async (state) => {
                const orderItemIndex = state.items.findIndex(orderItem => orderItem?.productId === productId);
                if (orderItemIndex > -1) {
                    const removedElement = state.items.splice(orderItemIndex, 1);
                    this.addLog(`Suppression du produit ${removedElement.getReference()} (qte: ${removedElement.quantity})`);
                }
            })
        },
        removeItemByReference(reference) {
            return this.$patch(async (state) => {
                const orderItemIndex = state.items.findIndex(orderItem => orderItem?.reference === reference);
                if (orderItemIndex > -1) {
                    const removedElement = state.items.splice(orderItemIndex, 1);
                    this.addLog(`Suppression du produit ${removedElement.getReference()} (qte: ${removedElement.quantity})`);
                }
            })
        },
        async addProductToCart({
            productId,
            quantityToAdd = null
        }) {
            const productsStore = useProductsStore();
            let product = new Product({
                id: productId
            });

            try {
                await productsStore.fetchProduct({id: productId});
                /** @type {Product} product */
                product = productsStore.productById(productId);
            } catch (e) {
                console.warn('erreur fetch Produit', e);
                product.updateMe(productsStore.productById(productId));
            }


            if (!quantityToAdd) {
                quantityToAdd = product.getQuantityStep();
            }
            return this.setItem({
                quantityToAdd,
                reference: product.reference,
                price: product.getSellPrice(),
                productId,
                unit: product.getSellUnit()
            });
        },
        /**
         * Add or update the quantity of an item to the cart
         * @param reference
         * @param price
         * @param {Number} quantityToAdd The quantity to add
         * @param productId
         * @param unit
         * @param {Number} [quantity] if not specified, the current orderItem quantity is taken or 0 if not current orderItem
         * @param {boolean} autoRemove Auto remove the item if its quantity end to 0 after adding the quantityToAdd
         * @returns {OrderItem>}
         */
        setItem({
            reference = null,
            price = null,
            quantityToAdd = 0,
            productId = null,
            unit = null,
            quantity = undefined,
            autoRemove = true,
            comment = ''
        } = {}) {
            let orderItem = getOrderItemByReference(this)(reference);
            if (productId) {
                orderItem = getOrderItemByProductId(this)(productId)
            }

            if (quantity === null || quantity === undefined) {
                quantity = orderItem?.quantity || 0;
            }

            if (!orderItem) {
                orderItem = new OrderItem({
                    productId,
                });
                this.items.push(orderItem);
            }

            if (quantity === 0 && quantityToAdd > 0) {
                this.addLog(`Ajout du produit ${orderItem.getReference()}`);
            }

            if (quantity > 0 && (quantityToAdd > 0 || orderItem.quantity !== quantity+quantityToAdd)) {
                this.addLog(`Mise à jour de la quantité du produit ${orderItem.getReference()} (de ${orderItem.quantity} à ${quantity+quantityToAdd})`);
            }

            orderItem.quantity = quantity + quantityToAdd;
            if (reference && reference !== orderItem.reference) orderItem.reference = reference;
            if (price && price !== orderItem.price) orderItem.price = price;
            if (unit && unit !== orderItem.unit) orderItem.unit = unit;
            if (comment && comment !== orderItem.comment) orderItem.comment = comment;

            if (autoRemove && orderItem.quantity === 0) {
                this.removeItem(orderItem);
                orderItem._autoremoved = true;
            }
            return orderItem;
        }
    }
});
