import TimestampableModel from "@/src/models/TimestampableModel";
import {useApi} from "@/src/api";
import dayjs from "~/src/dayjs.js";
import {Image} from "~/src/models/Image.js";
import {Seo} from "~/src/models/Seo.js";



export default class Actualite extends TimestampableModel.extend({
    id: [String, Number, null, undefined],
    title: [String, null, undefined],
    slug: [String, null, undefined],
    summary: [String, null, undefined],
    content: [String, Object, null, undefined],
    date: [String,null, undefined],
    image: [Image, null, undefined],
    seo: [Seo, null, undefined],
}).defaultTo({
    id: null,
    title: '',
    slug: '',
    summary: '',
    content: [],
    date: null
}) {

    constructor({id, title, slug, summary, content, date, image, seo}) {
        super({id, title, slug, summary, content, date, image, seo});
    }

    /**
     * @member
     * @return {dayjs.Dayjs}
     */
    get date$() { return dayjs(this.date); }

    get datetime() {
        return dayjs(this.date).format();
    }

    toJSON() {
        return {...this};
    }

}
Actualite.createFromStrapi4 = function (obj) {
    return new Actualite({
        id: obj.id,
        ...obj.attributes,
        image: obj.attributes.image?.data?.attributes || null
    });
}
