import { default as _91_46_46_46slug_93NkWwLxryAuMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/[...slug].vue?macro=true";
import { default as _404rwWgOMkyEBMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/_404.vue?macro=true";
import { default as actualiteOVkTuNkeOUMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/actualite.vue?macro=true";
import { default as _91slug_93CkdlBU3uqRMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/actualites/[slug].vue?macro=true";
import { default as indexhJg8ffcr1dMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/actualites/index.vue?macro=true";
import { default as cartnMkLMiARPHMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/cart.vue?macro=true";
import { default as _91slug_93k7m8mysbp1Meta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/categorie/[slug].vue?macro=true";
import { default as connexionQkFMLL02n2Meta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/connexion.vue?macro=true";
import { default as faq7YfmfOv5rfMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/faq.vue?macro=true";
import { default as index8hQkNX32U7Meta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/index.vue?macro=true";
import { default as inscriptionwljOYKFLXJMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/inscription.vue?macro=true";
import { default as l_45epicerie2X5lloaAwJMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/l-epicerie.vue?macro=true";
import { default as le_45vraci3cjJybE35Meta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/le-vrac.vue?macro=true";
import { default as mentionsIs3tahE3p7Meta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/mentions.vue?macro=true";
import { default as mon_45profilQLDQFwnFwNMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/mon-profil.vue?macro=true";
import { default as product_45catalogPdB91DjYxAMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/product-catalog.vue?macro=true";
import { default as _91slug_93eXNzonsUwvMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/produit/[slug].vue?macro=true";
import { default as slot_45view5arh75y3HYMeta } from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/slot-view.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93NkWwLxryAuMeta?.name ?? "slug",
    path: _91_46_46_46slug_93NkWwLxryAuMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93NkWwLxryAuMeta || {},
    alias: _91_46_46_46slug_93NkWwLxryAuMeta?.alias || [],
    redirect: _91_46_46_46slug_93NkWwLxryAuMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _404rwWgOMkyEBMeta?.name ?? "_404",
    path: _404rwWgOMkyEBMeta?.path ?? "/_404",
    meta: _404rwWgOMkyEBMeta || {},
    alias: _404rwWgOMkyEBMeta?.alias || [],
    redirect: _404rwWgOMkyEBMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/_404.vue").then(m => m.default || m)
  },
  {
    name: actualiteOVkTuNkeOUMeta?.name ?? "actualite",
    path: actualiteOVkTuNkeOUMeta?.path ?? "/actualite",
    meta: actualiteOVkTuNkeOUMeta || {},
    alias: actualiteOVkTuNkeOUMeta?.alias || [],
    redirect: actualiteOVkTuNkeOUMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/actualite.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93CkdlBU3uqRMeta?.name ?? "actualites-slug",
    path: _91slug_93CkdlBU3uqRMeta?.path ?? "/actualites/:slug()",
    meta: _91slug_93CkdlBU3uqRMeta || {},
    alias: _91slug_93CkdlBU3uqRMeta?.alias || [],
    redirect: _91slug_93CkdlBU3uqRMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/actualites/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexhJg8ffcr1dMeta?.name ?? "actualites",
    path: indexhJg8ffcr1dMeta?.path ?? "/actualites",
    meta: indexhJg8ffcr1dMeta || {},
    alias: indexhJg8ffcr1dMeta?.alias || [],
    redirect: indexhJg8ffcr1dMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/actualites/index.vue").then(m => m.default || m)
  },
  {
    name: cartnMkLMiARPHMeta?.name ?? "cart",
    path: cartnMkLMiARPHMeta?.path ?? "/cart",
    meta: cartnMkLMiARPHMeta || {},
    alias: cartnMkLMiARPHMeta?.alias || [],
    redirect: cartnMkLMiARPHMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93k7m8mysbp1Meta?.name ?? "categorie-slug",
    path: _91slug_93k7m8mysbp1Meta?.path ?? "/categorie/:slug()",
    meta: _91slug_93k7m8mysbp1Meta || {},
    alias: _91slug_93k7m8mysbp1Meta?.alias || [],
    redirect: _91slug_93k7m8mysbp1Meta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/categorie/[slug].vue").then(m => m.default || m)
  },
  {
    name: connexionQkFMLL02n2Meta?.name ?? "connexion",
    path: connexionQkFMLL02n2Meta?.path ?? "/connexion",
    meta: connexionQkFMLL02n2Meta || {},
    alias: connexionQkFMLL02n2Meta?.alias || [],
    redirect: connexionQkFMLL02n2Meta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/connexion.vue").then(m => m.default || m)
  },
  {
    name: faq7YfmfOv5rfMeta?.name ?? "faq",
    path: faq7YfmfOv5rfMeta?.path ?? "/faq",
    meta: faq7YfmfOv5rfMeta || {},
    alias: faq7YfmfOv5rfMeta?.alias || [],
    redirect: faq7YfmfOv5rfMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: index8hQkNX32U7Meta?.name ?? "index",
    path: index8hQkNX32U7Meta?.path ?? "/",
    meta: index8hQkNX32U7Meta || {},
    alias: index8hQkNX32U7Meta?.alias || [],
    redirect: index8hQkNX32U7Meta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: inscriptionwljOYKFLXJMeta?.name ?? "inscription",
    path: inscriptionwljOYKFLXJMeta?.path ?? "/inscription",
    meta: inscriptionwljOYKFLXJMeta || {},
    alias: inscriptionwljOYKFLXJMeta?.alias || [],
    redirect: inscriptionwljOYKFLXJMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/inscription.vue").then(m => m.default || m)
  },
  {
    name: l_45epicerie2X5lloaAwJMeta?.name ?? "l-epicerie",
    path: l_45epicerie2X5lloaAwJMeta?.path ?? "/l-epicerie",
    meta: l_45epicerie2X5lloaAwJMeta || {},
    alias: l_45epicerie2X5lloaAwJMeta?.alias || [],
    redirect: l_45epicerie2X5lloaAwJMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/l-epicerie.vue").then(m => m.default || m)
  },
  {
    name: le_45vraci3cjJybE35Meta?.name ?? "le-vrac",
    path: le_45vraci3cjJybE35Meta?.path ?? "/le-vrac",
    meta: le_45vraci3cjJybE35Meta || {},
    alias: le_45vraci3cjJybE35Meta?.alias || [],
    redirect: le_45vraci3cjJybE35Meta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/le-vrac.vue").then(m => m.default || m)
  },
  {
    name: mentionsIs3tahE3p7Meta?.name ?? "mentions",
    path: mentionsIs3tahE3p7Meta?.path ?? "/mentions",
    meta: mentionsIs3tahE3p7Meta || {},
    alias: mentionsIs3tahE3p7Meta?.alias || [],
    redirect: mentionsIs3tahE3p7Meta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/mentions.vue").then(m => m.default || m)
  },
  {
    name: mon_45profilQLDQFwnFwNMeta?.name ?? "mon-profil",
    path: mon_45profilQLDQFwnFwNMeta?.path ?? "/mon-profil",
    meta: mon_45profilQLDQFwnFwNMeta || {},
    alias: mon_45profilQLDQFwnFwNMeta?.alias || [],
    redirect: mon_45profilQLDQFwnFwNMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/mon-profil.vue").then(m => m.default || m)
  },
  {
    name: product_45catalogPdB91DjYxAMeta?.name ?? "product-catalog",
    path: product_45catalogPdB91DjYxAMeta?.path ?? "/product-catalog",
    meta: product_45catalogPdB91DjYxAMeta || {},
    alias: product_45catalogPdB91DjYxAMeta?.alias || [],
    redirect: product_45catalogPdB91DjYxAMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/product-catalog.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93eXNzonsUwvMeta?.name ?? "produit-slug",
    path: _91slug_93eXNzonsUwvMeta?.path ?? "/produit/:slug()",
    meta: _91slug_93eXNzonsUwvMeta || {},
    alias: _91slug_93eXNzonsUwvMeta?.alias || [],
    redirect: _91slug_93eXNzonsUwvMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/produit/[slug].vue").then(m => m.default || m)
  },
  {
    name: slot_45view5arh75y3HYMeta?.name ?? "slot-view",
    path: slot_45view5arh75y3HYMeta?.path ?? "/slot-view",
    meta: slot_45view5arh75y3HYMeta || {},
    alias: slot_45view5arh75y3HYMeta?.alias || [],
    redirect: slot_45view5arh75y3HYMeta?.redirect || undefined,
    component: () => import("/Users/thierrypoinot/Sites/isha-vrac/nuxt3/pages/slot-view.vue").then(m => m.default || m)
  }
]