import {getMediaUrl} from "@/src/strapi";
import TimestampableModel from "@/src/models/TimestampableModel";
import {Any} from "objectmodel";
import {Category} from "@/src/models/Category";

/**
 * @memberOf models
 * @typedef Image
 * @property id
 * @property {Object} formats
 * @property {StrapiImageFormat} formats.large
 * @property {StrapiImageFormat} formats.medium
 * @property {StrapiImageFormat} formats.small
 * @property {StrapiImageFormat} formats.thumbnail
 * @property {string} name
 * @property {string} url
 * @property {string} alternativeText
 * @property {string} caption
 * @property {number} width
 * @property {number} height
 * @property {string} previewUrl
 * @property {*} provider
 * @property {Object} provider_metadata
 */
export class Image extends TimestampableModel.extend({
    id: [Number],
    formats: [Any],
    name: [String],
    alternativeText: [String],
    caption: [String],
    width: [Number],
    height: [Number],
    url: String,
    previewUrl: [String],
    provider: [Any],
    provider_metadata: [Any]
}).defaultTo({
    alternativeText: '',
    caption: '',
}) {
    getSrcUrl(format = 'thumbnail') {
        if (this.formats && this.formats.format && this.formats.format?.url) {
            return getMediaUrl(this.formats[format].url);
        }
        return getMediaUrl(this.url);
    }

    hasFormat(format) {
        return this.formats?.[format] && this.formats?.[format]?.url;
    }

    toJSON() {
        return {...this};
    }

}

// import {Strapi4SingleEntryApiResponse} from '@/src/strapi.js'

/**
 * @memberOf ApiResponsesEntities
 * @typedef StrapiImageFormat
 * @property {string} name
 * @property {string} ext
 * @property {string} hash
 * @property {number} height
 * @property {number} width
 * @property {string} mime
 * @property {string} path
 * @property {string} url
 */

/**
 * @memberOf ApiResponsesEntities
 * @typedef StrapiImageContentType
 * @extends Strapi4SingleEntryApiResponse
 * @property {string} attributes.alternativeText
 * @property {string} attributes.caption
 * @property {string} attributes.ext
 * @property {string} attributes.hash
 * @property {string} attributes.mime
 * @property {string} attributes.name
 * @property {string} attributes.provider
 * @property {number} attributes.size
 * @property {string} attributes.url
 * @property {StrapiImageFormat[]} attributes.formats
 * @property {number} attributes.height
 * @property {number} attributes.width

 */

/**
 * @param {StrapiImageContentType} i
 * @returns {Image}
 */
export function createFromStrapi4(i) {
    if (!i) return null;
    const attributes = i?.attributes || {};
    return new Image({id: i.id, ...attributes});
}
