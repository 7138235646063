import {Category, createFromStrapi4 as createCategoryFromStrapi4} from '@/src/models/Category';
import {Image, createFromStrapi4 as createImageFromStrapi4} from "@/src/models/Image";
import TimestampableModel from "@/src/models/TimestampableModel";
import {Seo} from "@/src/models/Seo";
import {ArrayModel, ObjectModel} from "objectmodel";
import {useApi} from "@/src/api";

export const roundPrice = (price) => Math.round(parseFloat(price)*100)/100;
export const formatPrice = (price) => roundPrice(price).toLocaleString('fr-FR');
export const fetchProduct = async (id) => await (useApi().products.findOne({id}))
export const fetchProductBySlug = async (slug) => await (useApi().products.findOne({slug}))

const getUnitMultiplier = (unitSrc, unitDest) => {
    switch (true) {
        case unitSrc === unitDest:
            return 1;
        case unitSrc === 'weight_kg' && unitDest === 'weight_g':
        case unitSrc === 'volume_l' && unitDest === 'volume_ml':
            return 0.001;
        case unitSrc === 'weight_g' && unitDest === 'weight_kg':
        case unitSrc === 'volume_ml' && unitDest === 'volume_l':
            return 1000;

        case unitSrc === 'volume_l' && unitDest === 'volume_cl':
        case unitSrc === 'volume_cl' && unitDest === 'volume_ml':
            return 0.01;
        case unitSrc === 'volume_cl' && unitDest === 'volume_l':
        case unitSrc === 'volume_ml' && unitDest === 'volume_cl':
            return 100
    }
}


/**
 * Product model
 * @class Product
 * @see module:api.products {@link module:api#products}
 * @property {number|string} [id] If not set, the enitty is not yet persisted
 * @property {string} title
 * @property {string} description
 * @property {string} price_display
 * @property {number} price
 * @property {string} unit
 * @property {string} sellUnit
 * @property {number} quantityStep
 * @property {string} slug
 * @property {null|Array.<Category>} [categories]
 * @property {null|Image} [image]
 * @property {null|Array.<Image>} [images]
 * @property {boolean} out_of_stock
 * @property {boolean} disable_online_shopping
 */
export class Product extends TimestampableModel.extend({
    id: [Number,String],
    title: [String],
    description: [String],
    price_display: [String, null, undefined],
    price: [Number],
    unit: ['unit', 'weight_g', 'weight_kg', 'volume_ml', 'volume_cl', 'volume_l', undefined, null],
    quantityStep: [Number, null, undefined],
    sellUnit: ['unit', 'weight_g', 'weight_kg', 'volume_ml', 'volume_cl', 'volume_l', undefined, null],
    image: [Image, null, undefined],
    images: [ArrayModel(Image), null, undefined],
    slug: [String],
    categories: [Array, undefined, null],
    out_of_stock: [Boolean],
    disable_online_shopping: [Boolean],
    anonymous_can_view_price: [Boolean],
    seo: [Seo, undefined, null]
}).defaultTo({
    images: [],
    categories: [],
    image: null,
    price_display: '',
    unit: 'unit',
    quantityStep: 1,
    sellUnit: null,
}) {


    get mainImage() {
        if (this.image) {
            return new Image(this.image);
        }
        else if (this.images.length > 0) {
            return new Image(this.images[0]);
        }

        return new Image({url: 'assets/empty.jpg'});
    }

    updateMe({categories, images, image, price, price_display, unit, ...props}) {
        if (categories && categories.length > 0) {
            this.categories = categories.map(category => new Category(category));
        }
        if (image) {
            this.image = new Image(image);
        }
        if (images && images.length > 0) {
            this.images = images.map(img => new Image(img));
        }

        if (price_display) {
            this.price_display = price_display;
        }
        if (price) {
            this.price = parseFloat(price);
        }
        if (unit) {
            this.unit = unit;
        }
    }

    getSellUnit() {
        if (this.sellUnit) return this.sellUnit;
        return this.unit;
    }

    getQuantityStep() {
        if (this.quantityStep) return this.quantityStep;
        return 1;
    }
    getSellPrice(quantity = 1) {
        return this.getPrice(this.getSellUnit(), quantity)
    }
    getPrice(unit, quantity = 1) {
        return quantity * this.price * getUnitMultiplier(this.unit, unit);
    }

    formatPrice() {
        const DISPLAY = 'price_per_quantity_step'
        // const DISPLAY = 'price_per_unit'
        // const DISPLAY = 'price_display'

        try {
            if (DISPLAY === 'price_per_quantity_step') {
                const unit = this.getSellUnit();
                const multiplicator = this.getQuantityStep();
                // Get unit sell price
                const price = multiplicator*this.getPrice(unit);
                if (!unit || unit === 'unit') {
                    return `${formatPrice(price)} €`;
                }
                return `${formatPrice(price)} € /  ${multiplicator}${getUnitLabel(unit)}`;
            }

            else if (DISPLAY === 'price_per_unit') {
                const unit = this.unit;
                const price = this.getPrice(unit);
                if (!unit || unit === 'unit') {
                    return `${formatPrice(price)} €`;
                }
                return `${formatPrice(price)} € / ${getUnitLabel(unit)}`;
            }

            else if (DISPLAY === 'price_display') {
                return this.price_display.trim();
            }
        }
        catch (e) {
            this.getQuantityStep();
            // Get unit sell price
            const price = this.getPrice(unit);
            if (!unit || unit === 'unit') {
                return `${formatPrice(price)} €`;
            }
            return `${formatPrice(price)} € / ${getUnitLabel(unit)}`;
        }
    }

    toJSON() {
        return {...this};
    }
}



/** import {Strapi4SingleEntryApiResponse} from '@/src/strapi.js' */
/** import {StrapiCategoryContentType} from '@/src/models/Category.js' */
/** import {StrapiImageContentType} from '@/src/models/Image.js' */


/**
 * @memberOf ApiResponsesEntities
 * @typedef StrapiProduitContentType
 * @extends Strapi4SingleEntryApiResponse
 * @property {string} attributes.title
 * @property {string} attributes.description
 * @property {number} attributes.price
 * @property {string|null} attributes.price_display
 * @property {Object|null} attributes.metadata
 * @property {StrapiImageContentType} attributes.image.data The main image of the product
 * @property {StrapiImageContentType[]|Array} attributes.medias.data Others images of the product
 * @property {string} attributes.slug
 * @property {string} attributes.unit
 * @property {string} attributes.out_of_stock
 * @property {string} attributes.disable_online_shopping
 * @property {Strapi4SingleEntryApiResponse} attributes.fournisseur.data
 * @property {StrapiCategoryContentType[]} attributes.categories.data
 */

/**
 * @param {StrapiProduitContentType} p
 * @returns {Product}
 */
export function createFromStrapi4(p) {
    if (!p) return null;
    const categoriesData = p?.attributes?.categories?.data || [];
    const mediasData = p?.attributes?.medias?.data || [];
    const mainImageData = p?.attributes?.image?.data || null;
    const productId = p?.id || undefined;
    const seo = new Seo(p.attributes.seo || {});
    const attributes = p?.attributes || {};
    const categories = categoriesData.map(createCategoryFromStrapi4);
    const medias = mediasData.map(createImageFromStrapi4);
    const mainImage = createImageFromStrapi4(mainImageData);
    return new Product({id: productId, ...attributes, seo, categories, images: medias, image: mainImage});
}

export function getUnitLabel(unitKey) {
    if (unitKey === 'weight_g') {
        return 'g';
    }
    else if (unitKey === 'weight_kg') {
        return 'kg';
    }
    else if (unitKey === 'volume_ml') {
        return 'mL';
    }
    else if (unitKey === 'volume_cl') {
        return 'cL';
    }
    else if (unitKey === 'volume_l') {
        return 'L';
    }
    else if (unitKey === 'unit' || unitKey === 'piece') {
        return 'piece';
    }
    return unitKey;
}
