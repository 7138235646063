import {Model} from "objectmodel";
import dayjs from "@/src/dayjs";

/**
 * @class Slot
 * @property {String} start
 * @property {dayjs.Dayjs} start$
 * @property {String} end
 * @property {dayjs.Dayjs} end$
 * @property {Number} availableCount
 */
export default class Slot extends Model({
    start: [String],
    end: [String],
    availableCount: [Number],
}) {

    /**
     * @member
     * @return {dayjs.Dayjs}
     */
    get start$() { return dayjs(this.start); }

    /**
     * @member
     * @return {dayjs.Dayjs}
     */
    get end$() { return dayjs(this.end); }

    toJSON() {
        return {...this};
    }
}
