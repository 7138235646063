/**
 * Return the day number of the week from a day name
 * Default behaviour is for french language
 * @param {string} dayStr The day name
 * @param {boolean} [returnJsIndex] if true, the return value is the JS index day of week
 * @return {int}
 */
export const getWeekDayNumber = (dayStr, returnJsIndex = false) => {
    let weekDayNumber = undefined;
    switch(dayStr.toLowerCase()) {
        case 'monday':
        case 'lundi':
            weekDayNumber = returnJsIndex ? 1 : 0;
            break;
        case 'tuesday':
        case 'mardi':
            weekDayNumber = returnJsIndex ? 2 : 1;
            break;
        case 'wednesday':
        case 'mercredi':
            weekDayNumber = returnJsIndex ? 3 : 2;
            break;
        case 'thursday':
        case 'jeudi':
            weekDayNumber = returnJsIndex ? 4 : 3;
            break;
        case 'friday':
        case 'vendredi':
            weekDayNumber = returnJsIndex ? 5 : 4;
            break;
        case 'saturday':
        case 'samedi':
            weekDayNumber = returnJsIndex ? 6 : 5;
            break;
        case 'sunday':
        case 'dimanche':
            weekDayNumber = returnJsIndex ? 0 : 6;
            break;

    }
    return weekDayNumber;
}

export const getWeekDayJsNumber = (dayStr) => {
    return getWeekDayNumber(dayStr, true);
}
