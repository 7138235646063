import dayjs from "@/src/dayjs";

/**
 * @typedef Address
 * @property label
 * @property name
 * @property street
 * @property complement
 * @property postcode
 * @property city
 * @property country
 * @property phone
 */

export default class User {
    id;
    email;
    username;

    /**
     * @type Address[]
     */
    addresses;

    /**
     * @property {Boolean} confirmed
     */
    confirmed;
    firstname;
    lastname;
    profile;

    /**
     * @property {dayjs.Dayjs} updatedAt
     */
    updatedAt;

    /**
     * @property {dayjs.Dayjs} createdAt
     */
    createdAt;

    constructor({updated_at, created_at, confirmed, ...props}) {
        this.updatedAt = dayjs(updated_at);
        this.createdAt = dayjs(updated_at);
        this.confirmed = !!confirmed;
        Object.keys(props).map(propName => {
            if (props[propName] && propName in this) {
                this[propName] = props[propName];
            }
        })
    }

    getDisplayName() {
        if (this.firstname) {
            if (this.lastname) {
                return `${this.firstname} ${this.lastname.toUpperCase()[0]}.`
            }
            return this.firstname;
        }
        return this.username;
    }

    toJSON() {
        return {...this};
    }
}
