import TimestampableModel from "@/src/models/TimestampableModel";
import {Seo} from "@/src/models/Seo";

export default class Page extends TimestampableModel.extend({
    id: [Number,String,undefined],
    title: [String],
    subtitle: [String],
    path: [String],
    slug: [String],
    content: [String],
    seo: [Seo, null, undefined]
}) {

    toJSON() {
        return {...this};
    }
}
Page.createFromStrapi4 = function (obj) {
    return new Page({id: obj.id, ...obj.attributes})
}
