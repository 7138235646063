import SsdStorage from "~/src/functions/SsdStorage.js";

export const useStorage = () => {
    const runtimeConfig = useRuntimeConfig();
    let storage = new SsdStorage(runtimeConfig.public.localstoragePrefix+'-user', 'memory');
    try {
        storage = new SsdStorage(runtimeConfig.public.localstoragePrefix+'-user', 'localstorage');
    }
    catch (e) {
        storage = new SsdStorage(runtimeConfig.public.localstoragePrefix+'-user', 'memory');
    }
    return storage;
}
