<template>
  <div id="pinia-store"></div>
</template>
<script setup>
import {getAuthToken, isTokenValid} from "~/src/auth";
import {useApi} from "~/src/api";
import OrderItem from "~/src/models/OrderItem";
import AddressComponent from "~/src/models/AddressComponent";
import SsdStorage from "~/src/functions/SsdStorage";
import {onMounted} from "vue";
import {useCartStore} from "~/stores/cart.js";
import {useProductsStore} from "~/stores/products.js";
import {useStrapiUserStore} from "~/stores/strapi-user.js";
import {useStorage} from "~/composables/useStorage.js";

const cartStore = useCartStore();
const productsStore = useProductsStore();
const userStore = useStrapiUserStore();

const api = useApi();

onMounted(async () => {
  try {
    userStore.token = getAuthToken();
    if (isTokenValid()) {
      userStore.user = await api.user.me()
    }
  }
  catch (err) {
    console.warn(`Erreur lors de l'initialisation du store utilisateur`, err)
  }
});
onMounted(() => {
  // Chargement du panier depuis le localStorage en mémoire
  cartStore.$patch(async (state) => {
    const storageState = useStorage().getItem('state', {
      items: [],
      lastSend: null,
      lastUpdate: null,
      dateRetrait: null,
      id: null
    });
    state.id = storageState.id;
    try {
      state.items = storageState.items.map(orderItem => new OrderItem(orderItem))
    }
    catch (e) {
      state.items = [];
      console.warn('Error lors de la restauration du panier');
    }
    state.lastSend = storageState.lastSend;
    state.log = storageState.log;
    cartStore.addLog(`Chargement LocalStorage`);
    state.dateRetrait = storageState.dateRetrait;
    state.billingAddressName = storageState.billingAddressName;
    state.billingAddressStreet = storageState.billingAddressStreet;
    state.billingAddressComplement = storageState.billingAddressComplement;
    state.billingAddressPostcode = storageState.billingAddressPostcode;
    state.billingAddressCity = storageState.billingAddressCity;
    state.billingAddressCountry = storageState.billingAddressCountry;
    await Promise.all([...state.items].map(i => {
      try {
        productsStore.fetchProduct({id: i.productId})
      }
      catch (e) {
        console.error('Le produit associé à cet élément de panier est corrompu', {...i});
      }
    }));
  });

  cartStore.$subscribe(async (mutation, state) => {
    if (mutation.storeId === 'cart') {
      useStorage().setItem('state', state);
    }
  })
  cartStore.$onAction(({name, after}) => {
    if (name === 'setItem') {
      after(async () => {
        await cartStore.saveCartRemotely();
      });
    }
  })
});
</script>
