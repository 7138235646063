import {useStrapi, useEntityApi, useSingleTypeEntityApi, useSluggableEntityApi} from '@/src/strapi';
import {Product, createFromStrapi4 as createProductFromStrapi4} from "@/src/models/Product";
import {Category, createFromStrapi4 as createCategoryFromStrapi4} from "@/src/models/Category";
import Order from "@/src/models/Order";
import {setAuthToken} from "@/src/auth";
import OrderItem from "@/src/models/OrderItem";
import Page from "@/src/models/Page";
import User from "@/src/models/User";
import {getErrorMessageFromAxiosResponse} from "@/src/datas/strapi-api-messages";
import Slot from "@/src/models/Slot";
import {getWeekDayJsNumber, getWeekDayNumber} from "~/src/functions/getWeekDayJsNumber.js";
import dayjs from "~/src/dayjs.js";
import Actualite from "~/src/models/Actualite.js";



/**
 * @module api
 * @example import {useApi} from './api.js
 * @example const api = useApi()
 */

/**
 * @typedef {module:strapi.SingleTypeEntityApi} SingleTypeEntityApi
 */


/**
* @typedef HomePageResponse
* @property {number} id
* @property {string} body
* @property published_at
* @property created_at
* @property updated_at
*/

/**
* @typedef SettingResponse
* @property {boolean} maintenance
* @property {boolean} enableBlog
*/

/**
* @typedef HorairePeriodResponse
* @property {string} from
* @property {string} to
*/

/**
* @typedef OpeningHoursResponse
* @property {array<HorairePeriodResponse>} monday
* @property {array<HorairePeriodResponse>} tuesday
* @property {array<HorairePeriodResponse>} wednesday
* @property {array<HorairePeriodResponse>} thursday
* @property {array<HorairePeriodResponse>} friday
* @property {array<HorairePeriodResponse>} saturday
* @property {array<HorairePeriodResponse>} sunday
*/

/**
* @typedef HoraireRetraitResponse
* @property {number} maxOrderBySlot
* @property {number} slotDuration
* @property {array<HorairePeriodResponse>} disabledDates
* @property {array<HorairePeriodResponse>} enabledDates
* @property {OpeningHoursResponse} openingHours
*/

/**
 * @typedef QuestionAnswer
 * @property {number} id
 * @property {string} answer
 * @property {string} question
 */
/**
 * @typedef Group
 * @property {number} id
 * @property {string} title
 * @property {QuestionAnswer[]} question_answer
 */
/**
 * @typedef FaqResponse
 * @property {number} id
 * @property {string} title
 * @property {string} description
 * @property published_at
 * @property created_at
 * @property updated_at
 * @property {Group[]} groups
 */

/**
 * @typedef OpeningHour
 * @property {string} day
 * @property {string} dayStr The day name in the current locale of dayjs
 * @property {array<HorairePeriodResponse>} hours The day name in the current locale of dayjs
 * @property {number} jsDayNumber The JS index of the day of week (0 for sunday, 1 for monday ...)
 * @property {number} dayNumber The french day number
 */


/**
 * @typedef AuVracApi
 * @see api:api
 * @property {Promise<any>} cart
 * @property {Promise<HomePageResponse>} homepage
 * @property {Promise<HoraireRetraitResponse>} horaires
 * @property {Function<Promise<array<OpeningHour>>>} openingHours
 * @property {Promise<SettingResponse>} settings
 * @property {CollectionEntityApi<Page>} pages
 * @property {CollectionEntityApi<Order>} orders
 * @property {CollectionEntityApi<OrderItem>} orderItems
 * @property {CollectionEntityApi<Product>} products
 * @property {CollectionEntityApi<Category>} categories
 * @property user
 */

/**
  * An object describing all the data API of this APP
  */
export const useApi = () => {
    const strapi = useStrapi();

    /**
     * @type {{addItem(OrderItem): Promise<*>}}
     */
    const cart = {
        /**
         * @deprecated
         * @todo To refactore and use the orders api to update the component "order-items"
         * @param {number|string} productId
         * @param {OrderItem} orderItem
         * @return {Promise<OrderItem>}
         */
        async addItem({
                          product: {id: productId},
                          ...orderItem
                      }) {
            return strapi.create('order-items', {product: productId, ...orderItem});
        }
    }

    /**
     * @type {SingleTypeEntityApi<Object>}
     */
    const faq = useSingleTypeEntityApi('foire-aux-question', (faq) => faq, ['groups', 'groups.question_answer', 'seo', 'seo.metaSocial']);

    /**
     * @type {SingleTypeEntityApi<Object>}
     */
    const homepage = useSingleTypeEntityApi('home', (home) => home,  ['seo', 'seo.metaSocial']);
    const settings = useSingleTypeEntityApi('setting', (obj) => obj,  []);
    const horaires = useSingleTypeEntityApi('horaire-de-retrait', (obj) => obj,  []);

    /**
     * @return {Promise<OpeningHour[]>}
     */
    const openingHours = async () => {
        const res = await horaires.fetch();
        const openingHours = Object.entries(res.openingHours).map(d => {
            let jsWeekDayNumber = getWeekDayJsNumber(d[0]);
            return {
                day: d[0],
                dayStr: dayjs().day(jsWeekDayNumber).format('dddd'),
                hours: d[1],
                jsDayNumber: jsWeekDayNumber,
                dayNumber: getWeekDayNumber(d[0])
            };
        }).sort((a, b) => a.dayNumber - b.dayNumber);
        return openingHours;
    };

    /**
     *
     * @type {SingleTypeEntityApi<U|*>}
     */
    const slots = useSingleTypeEntityApi('my-order/available-collect-slots', (slots) => slots.map((s) => new Slot(s)));

    /**
     * @type {SluggableEntityApi<Page>}
     */
    const pages = useSluggableEntityApi('pages', Page.createFromStrapi4, [ 'seo', 'seo.metaSocial']);

    /**
     * @type {CollectionEntityApi<Order>}
     */
    const orders = useEntityApi('my-orders', Order.createFromStrapi4, ['order_items', 'order_items.produit', 'order_items.produit.categories', 'billing_address']);


    const actualites = useEntityApi('actualites', Actualite.createFromStrapi4, ['image', 'seo']);

    return {
        cart,
        homepage,
        actualites,
        horaires,
        openingHours,
        settings,
        slots,
        faq,
        pages,
        orders,

        /** the products api */
        products: useSluggableEntityApi('produits', createProductFromStrapi4, ['categories', 'image', 'images', 'seo', 'seo.metaSocial']),

        /**
         * the categories of products
         * @type {CollectionEntityApi<Category>}
         */
        categories: useSluggableEntityApi('categories', createCategoryFromStrapi4),

        /**
         * @deprecated
         * @see Voir le plugin strapi 4 {@link https://market.strapi.io/plugins/strapi-plugin-passwordless}
         */
        passwordLess: {
            /**
             * @todo not tested
             * @deprecated removed since it is not stable
             * @param {string} [email]
             * @param {string} [username]
             */
            sendLink: async ({email = null, username = null, context = {}}) => {
                if (!email && !username) throw 'The email or username are required to send a passwordless login link';
                await strapi.createFetch({withAuthHeaders: false}).post(
                    `${strapi.baseUrl}/passwordless/send-link`,
                    {
                        email,
                        username,
                        context
                    }
                )
                        .catch(err => {
                            console.error(err);
                            return Promise.reject(err);
                        })
                ;
                return true;
            },
            /**
             * @todo not tested
             * @deprecated removed since it is not stable
             * @param loginToken
             * @returns {Promise<User>}
             */
            login: async ({loginToken}) => {
                /**
                 * @typedef PasswordLessLoginResponse
                 * @property {string} jwt
                 * @property {User} user
                 * @property {Object} context
                 */
                /**
                 * @type {AxiosResponse<PasswordLessLoginResponse>}
                 */
                const data = await strapi.createFetch({withAuthHeaders: false}).get(
                    `${strapi.baseUrl}/passwordless/login`,
                    {
                        params: {
                            loginToken
                        },
                    }
                );
                const token = data.jwt;
                setAuthToken(token);
                return new User(data.user);
            },
        },

       /**
        * User concerned helpers
        * @property {Object}
        */
        user: {
           /**
            * @todo Not yet implemented
            *
            * @property emailExists
            * @param email
            * @return {Promise<boolean>}
            */
            async emailExist({email}){
              return true;
            },
            /**
             * Authenticate a user and store token in storage
             * @see ./auth.js
             * @param identifier
             * @param password
             * @return {Promise<User>}
             */
            async login({identifier, password}) {
                const data = await strapi.login({identifier, password});
                const token = data.jwt
                setAuthToken(token);
                return new User(data.user);
            },
            /**
             * Call the server to send an email with a uniq link to reset password of a user
             * @param email
             * @return {Promise<User>}
             */
            async askForgotPasswordLink({email}) {
                const data = await strapi.forgotPassword({email});
                return getErrorMessageFromAxiosResponse(data);
            },
            /**
             * Call the server to send an email with a uniq link to reset password of a user
             * @param {string} code the code passed throught the url
             * @param {string} password
             * @param {string} passwordConfirmation
             * @return {Promise<true>}
             */
            async changeForgottenPassword({code, password, passwordConfirmation}) {
                const data = await strapi.changeForgottenPassword({code, password, passwordConfirmation});
                console.debug('changeForgottenPassword data', data);
                return getErrorMessageFromAxiosResponse(data);
            },

            /**
             * Register the user and automatically set the jwt token returned by the endpoint in localStorage
             * @param {object} authDetails
             * @param {string} authDetails.username
             * @param {string} authDetails.email
             * @param {string} authDetails.password
             * @param {object} options
             * @param {boolean} options.withLogin
             * @returns {Promise<User>}
             */
            async register (
                authDetails,
                {withLogin = true} = {withLogin: true}
            ) {
                // console.log('apolloClient', apolloClient);
                // try {
                //     const { data } = await apolloClient.mutate({ mutation: REGISTER_USER, variables: { ...authDetails } })
                //     const token = data.register.jwt
                //     if (withLogin) {
                //         setAuthToken(token);
                //     }
                //     return data.register;
                // } catch (e) {
                //     console.error(e)
                // }

                try {
                    //const { data } = await apolloClient.mutate({ mutation: REGISTER_USER, variables: { ...authDetails } })
                    const data = await strapi.register({...authDetails})
                    const token = data.jwt
                    if (withLogin) {
                        setAuthToken(token);
                    }
                    return new User(data.user);
                } catch (e) {
                    // console.error(e)
                    throw e;
                }
            },
            /**
             *
             * @return {Promise<User>}
             */
            async me() {
                return new User(await strapi.me());
            },
            updateMe({id, ...params}) {
                return strapi.update('users', id, params);
            },
        }
    };
}
