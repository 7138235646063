import {Product, getUnitLabel, formatPrice} from '@/src/models/Product';
import TimestampableModel from "@/src/models/TimestampableModel";
import {Any} from "objectmodel";
import Order from "@/src/models/Order";
import {useProductsStore} from "@/stores/products.js";



/**
 * OrderItem model
 * @class OrderItem
 * @usage new OrderItem({product, quantity: 1})
 * @usage const p = new Product();
 *        const orderItem = new OrderItem({product: p, quantity: 1});
 * @property {Number|string} [id]
 * @property {string} [reference]
 * @property {number} quantity
 * @property {Product} product
 * @property {number} price
 * @property {string} priceDisplay
 * @property {string} [unit]
 */
export default class OrderItem extends TimestampableModel.extend({
    id: [Number, null, String, undefined],
    reference: [String, undefined, null],
    productId: [Number, String, undefined, null],
    quantity: [Number],
    price: [Number],
    comment: [String, null],
    // order: [Order, Number, undefined],
    unit: ['unit', 'weight_g', 'weight_kg', 'volume_ml', 'volume_cl', 'volume_l', undefined, null],

}).defaultTo({
    id: undefined,
    product: undefined,
    reference : undefined,
    quantity: 0,
    price: 0,
    comment: '',
    unit: 'unit',
}) {
    constructor({
        id = undefined,
        productId = undefined,
        reference  = undefined,
        quantity = 0,
        price = 0,
        priceDisplay = '',
        unit = 'unit',
        ...attributes
    }) {
        //product = new Product(product);
        super({id, productId, reference, quantity, price, unit, ...attributes});
    }

    getReference() {
        try {
            return this.reference || `${this.product.title} #${this.product.id}`;
        }
        catch (e) {
            return this.reference;
        }
    }

    getTotal() {
        if (isNaN(this.quantity) || isNaN(this.price)) {
            return 0;
        }
        return this.quantity*this.price;
    }


    get product() {
        const productsStore = useProductsStore();
        return productsStore.productById(this.productId)
    }

    set product(val) {
        //console.error('Cannot set property product of OrderItem, property is readOnly', val);
    }

    toStrapi4UpdateObject() {
        return {
            // __component: 'order.order-item',
            id: this.id,
            reference: this.getReference(),
            quantity: this.quantity,
            price: this.price,
            comment: this.comment,
            unit: this.unit,
            produit: this.productId,
        }
    }


    /**
     * @param {Partial<Product>} product
     */
    updateProductInformations(product) {
        if (product) {
            if (this.product?.price) {
                this.price = this.product.price;
            }
            if (this.product?.priceDisplay) {
                this.priceDisplay = this.product.price_display;
            }
            if (this.product?.unit) {
                this.unit = this.product.unit;
            }
            if (this.product?.title) {
                this.reference = `${this.product.title} #${this.product.id}`;
            }
        }
    }

    formatTotal(defaultValue = '') {
        if (this.price && this.quantity) return formatPrice(this.price*this.quantity)+' €';
        return defaultValue;
    }


    formatPrice(defaultValue = '') {
        let output = defaultValue;
        if (this.price) {
            const unitLabel = getUnitLabel(this.unit);
            output = formatPrice(this.price)+' €';
            if (unitLabel) {
                output += ' /'+unitLabel;
            }
        }
        return output;
    }

    toJSON() {
        return {...this};
    }
}

// import {Strapi4SingleEntryApiResponse} from '@/src/strapi.js'
// import {StrapiProduitContentType} from '@/src/models/Product.js'

/**
 * @memberOf ApiResponsesEntities
 * @typedef StrapiOrderItemComponent
 * @property {Number} id
 * @property {object} produit
 * @property {StrapiProduitContentType} produit.data
 * @property {number} quantity
 * @property {number} price
 * @property {string} reference
 * @property {string} comment
 * @property {string} unit
 */

/**
 * @param {StrapiOrderItemComponent} ob
 * @return {OrderItem}
 */
OrderItem.createFromStrapi4 = function (ob) {
    return new OrderItem({
        // product: ob.produit,
        id: ob.id,
        reference: ob.reference,
        quantity: ob.quantity,
        price: ob.price,
        comment: ob.comment,
        unit: ob.unit,
        productId: ob?.produit?.data?.id || undefined
    });
}
