import { defineStore } from 'pinia'
import {useApi} from "~/src/api";
import {Product} from "~/src/models/Product";
import {Category} from "~/src/models/Category";
/**
 * @namespace products
 * @memberOf stores
 */

/**
 * @memberOf stores.products
 * @typedef ProductSearchState
 * @property {array} results
 * @property {string} query
 * @property {number} count
 * @property {number} totalCount
 * @property {number} offset
 * @property {number} limit
 */

/**
 * @memberOf stores.products
 * @typedef ProductsState
 * @property {ProductSearchState} search
 * @property {Product[]|array} products
 * @property {Category[]|array} categories
 * @property {number} currentSearchPage The current page of the search
 * @property {number} totalSearchPages The total number of pages for the current search
 * @property {number} nextSearchPage
 * @property {number} previousSearchPage
 * @method productById
 * @method productBySlug
 * @method categoryById
 * @method categoryBySlug
 * @method addOrUpdateProductToCollection
 * @method fetchProduct
 */


export const useProductsStore = defineStore({
  id: 'products',
  /**
   * @return {ProductsState}
   */
  state: () => ({
    search: {
      results: [],
      query: '',
      count: 0,
      totalCount: 0,
      offset: 0,
      limit: 10,
    },
    /**
     * {Product[]}
     */
    products: [],
    categories: [],
  }),
  getters: {
    api: () => useApi(),
    currentSearchPage: state => {
      if (state.search.offset > 0) {
        return Math.ceil(state.search.offset/state.search.limit)+1
      }
      return 1;
    },
    totalSearchPages: state => Math.ceil(state.search.totalCount/state.search.limit),
    nextSearchPage() {
      if (this.currentSearchPage < this.totalSearchPages) {
        return this.currentSearchPage + 1
      }
      return 1;
    },
    previousSearchPage() {
      if (this.currentSearchPage > 1) {
        return this.currentSearchPage - 1
      }
      return this.totalSearchPages;
    },
    productById: state => id => {
      return state.products.find((product => product?.id === id))
    },
    productBySlug: state => slug => {
      return state.products.find((product => product?.slug === slug))
    },
    categoryById: state => id => {
      return state.categories.find((category => category?.id === id))
    },
    categoryBySlug: state => slug => {
      return state.categories.find((category => category?.slug === slug))
    },
  },
  actions: {
    fetchProduct ({id, slug}) {
      return this.api.products.findOne({id, slug})
          .then(p => {
            this.addOrUpdateProductToCollection(p);
            return p;
          })
    },
    addOrUpdateProductToCollection(product) {
      if (!product) return;
      const productIndex = this.products.findIndex(p => p.id === product.id);
      if (productIndex < 0 || !this.products[productIndex]) {
        this.products.push(product);
      }
      else {
        if (this.products[productIndex].updateMe) {
          this.products[productIndex].updateMe(product);
        }
        else {
          this.products[productIndex] = new Product({...this.products[productIndex], ...product});
        }
      }
    },
    fetchProducts({search = '', offset = 0, limit = 10, filters = {}}) {
      return this.api.products.search({
        filters: {title: {$contains: search}, ...filters},
        pagination: {start:offset, limit}
      })
          .then(({data, meta}) => {
            data.forEach(d => this.addOrUpdateProductToCollection(d));
            this.search.results = data;
            this.search.count = data.length;
            this.search.limit = limit;
            this.search.offset = offset;
            this.search.query = search;
            this.search.totalCount = meta.pagination.total;
          })
          .catch(err => {
            console.error(err);
            this.products = [];
          })
        ;
    },
    fetchCategories () {
      // let cats = [];
      return this.api.categories.find({pagination: {limit: 100}})
          .then(cats => {
            console.log('success fetching categories')
            this.categories = cats
            return cats
          })
          .catch(err => console.log('fetchCategories error', err));
      ;
    },
    fetchCategory({slug, id}) {
      return this.api.categories.findOne({slug, id})
          .then(category => {
            const categoryIndex = this.categories.find((category => category?.id === id));
            if (categoryIndex < 0 || !this.categories[categoryIndex]) {
              this.categories.push(category);
            }
            else {
              /*** FIXME : we cant update the object */
              this.categories[categoryIndex].updateMe(category);
            }
          });
    }
  }
})
