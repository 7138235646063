import router_CrWB4n4PyO from "/Users/thierrypoinot/Sites/isha-vrac/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_6haAUckR5u from "/Users/thierrypoinot/Sites/isha-vrac/node_modules/nuxt/dist/app/plugins/debug.js";
import plugin_vue3_aQoh9E5uaZ from "/Users/thierrypoinot/Sites/isha-vrac/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/Users/thierrypoinot/Sites/isha-vrac/nuxt3/.nuxt/components.plugin.mjs";
import unhead_3Bi0E2Ktsf from "/Users/thierrypoinot/Sites/isha-vrac/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_5ZRYOjuei0 from "/Users/thierrypoinot/Sites/isha-vrac/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_kCUITUWXUN from "/Users/thierrypoinot/Sites/isha-vrac/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  router_CrWB4n4PyO,
  debug_6haAUckR5u,
  plugin_vue3_aQoh9E5uaZ,
  components_plugin_KR1HBZs4kY,
  unhead_3Bi0E2Ktsf,
  prefetch_client_5ZRYOjuei0,
  chunk_reload_client_kCUITUWXUN
]