import TimestampableModel from "@/src/models/TimestampableModel";

// import {Product} from './Product.js'

/**
 * The category of {@link Product}
 * @memberOf models
 */
export class Category extends TimestampableModel.extend({
    id: Number,
    name: String,
    slug: String,
}) {
    updateMe({name, id, slug}) {
        this.name = name;
        this.id = id;
        this.slug = slug;
    }
    toJSON() {
        return {...this};
    }
}

// import {Strapi4SingleEntryApiResponse} from '@/src/strapi.js'

/**
 * @namespace ApiResponsesEntities
 * @typedef StrapiCategoryContentType
 * @extends Strapi4SingleEntryApiResponse
 * @property {string} attributes.name
 * @property {string} attributes.slug

 */

/**
* @memberOf models.Category
 * @function
 * @param {StrapiCategoryContentType} c
 * @returns {Category}
 */
export function createFromStrapi4(c) {
    const attributes = c.attributes || {};
    return new Category({id: c.id, ...attributes});
}
