import axios from 'axios';
import {getAuthHeaders, getAuthToken, isTokenValid, resetAuthToken} from "@/src/auth";
import qs from 'qs';
// import {AxiosResponse, AxiosRequestConfig} from 'axios/index.d.ts';

/**
 * @module strapi
 * @example import {useStrapi} from './strapi.js
 * @example const strapi = useStrapi()
 */

/**
 * @external AxiosResponse
 * @see import('axios/index.d.ts').AxiosResponse
 * @see {@link https://axios-http.com/docs/res_schema AxiosResponse}
 */

/**
 * A function helper to ensure that medias url start with the strapi base url
 * @member
 * @param {string} url The url of the media
 * @return {string|*}
 */
export function getMediaUrl(url) {
    const runtimeConfig = useRuntimeConfig();
    // Check if URL is a local path
    if (
        url
        && !url.startsWith(runtimeConfig.public.strapiBaseUrl)
        && !url.startsWith('https://')
        && url.startsWith('/')
    ) {
        // Prepend Strapi address
        return `${runtimeConfig.public.strapiBaseUrl}${url}`
    }
    // Otherwise return full URL
    return url
}

/**
 * A function to parse entity EditorJS attribute
 *
 * @function
 * @param {string} data
 * @return {any}
 */
export function parseEditorJsAttribute(data) {
  return JSON.parse(data);
}


/**
 * @type Object
 * @typedef Strapi4ApiMetaPaginationByOffsetResponse
 * @property {number} start
 * @property {number} limit
 * @property {number} total
 */

/**
 * @type {Object}
 * @typedef Strapi4ApiMetaPaginationByPageResponse
 * @property {number} page
 * @property {number} pageSize
 * @property {number} pageCount
 * @property {number} total
 */
/**
* @member
 * @template Attributes
 * @typedef Strapi4SingleEntryApiResponse<Attributes>
 * @property {number} id
 * @property {Object|Attributes} attributes
 */
/**
 * @member
 * @typedef Strapi4RestError
 * @property {number} status HTTP Status
 * @property {string|'ApplicationError'|'ValidationError'} name Strapi error name ('ApplicationError' or 'ValidationError')
 * @property {string} message A human reable error message
 * @property {Object} details error info specific to the error type
 */
/**
 * @member
 * @typedef Strapi4ApiResponse<T>
 * @property {Object|null|Strapi4SingleEntryApiResponse|Strapi4SingleEntryApiResponse[]|T} data the response data itself
 * @property {Object} meta information about pagination, publication state, available locales, etc.
 * @property {Strapi4ApiMetaPaginationByOffsetResponse|Strapi4ApiMetaPaginationByPageResponse} [meta.pagination]
 * @property {Strapi4RestError} [error] information about any error thrown by the request
 */
/**
 * @typedef Strapi4PaginationByOffsetParams
 * @property {number} start default to 0
 * @property {number} limit default to 25
 * @property {boolean} withCount default to true
 */
/**
 * @typedef Strapi4PaginationByPageParams
 * @property {number} page default to 1
 * @property {number} pageSize default to 25
 * @property {boolean} withCount default to true
 */


/**
 * @typedef Strapi4QueryParams
 * @property {string[]} [fields] {@link https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#field-selection}
 * @property {string|Object|'*'} [populate] {@link https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields}
 */
/**
 * @typedef Strapi4SearchParams
 * @extends Strapi4QueryParams
 * @property {array} [sort] An array of the field name to order (default order is asc) (eg: ['title:asc', 'date:desc'])
 * @property {Object} [filters] {@link https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/filtering-locale-publication.html#filtering}
 * @property {Strapi4PaginationByPageParams|Strapi4PaginationByOffsetParams} [pagination]
 * @property {string|'live'|'preview'} [publicationState]
 * @property {string} [locale]
 *
 */


/**
 * @typedef StrapiAPI
 */
/**
 *  The strapi sdk
 * @global
 * @type {StrapiAPI}
 */
export const useStrapi = () => {
    const runtimeConfig = useRuntimeConfig();

    return {
        baseUrl: runtimeConfig.public.strapiBaseUrl+'/api',
        /**
         * @param {object} options
         * @param {boolean} [options.withAuthHeaders=true] Attach Authorisation Hearder with token to request, default: true
         * @param {boolean} [options.returnResponseData=true] Each request will return a Promise of the data of the response, default: true
         * @return external:AxiosInstance
         */
        createFetch(options = {}) {
            // const $fetch = useFetch();
            const _defaultOptions = {
                withAuthHeaders: true,
                returnResponseData: true,
                useOhMyFetch: true,
                useAxios: false
            };
            const {withAuthHeaders, returnResponseData} = {
                ..._defaultOptions,
                ...options
            };

            const authHeaders = {
                ...getAuthHeaders()
            };
            const runtimeConfig = useRuntimeConfig();


            //const f = useFetch();

            /** @type {AxiosInstance} */
            const f = $fetch.create({
                baseUrl: runtimeConfig.public.strapiBaseUrl,
                headers: withAuthHeaders ? authHeaders : {},
                responseType: 'json',
                // paramsSerializer: qs.stringify,
                async onResponseError({ request, response, options }) {
                    // Log error
                    console.warning('[fetch response error]', request, response.status, response)

                    // Si on a une erreur 401 avec un token "valide" on l'efface pour forcer à en récupérer un
                    // Gestion des cas où un token valide serait présent en localstorage mais dont le serveur ne voudrait pas
                    if (response && response.status === 401 && isTokenValid()) {
                        resetAuthToken();
                    }
                }

            });

            return (...args) => {
                try {
                    return f(...args)
                        .catch((error) => {
                            if (returnResponseData) {
                                return Promise.reject(error.data || error)
                            }
                            return Promise.reject(error);
                        })
                    ;
                }
                catch (e) {
                    console.error(e);
                    return Promise.reject(e);
                }
            }
        },


        /**
         * Return an array of strapi entity response
         * @param {string} entity The plural form of the entity name
         * @param {Strapi4SearchParams} searchParams
         * @return {Promise<AxiosResponse<Strapi4ApiResponse<Strapi4SingleEntryApiResponse>>>}
         */
        async find (entity, searchParams) {
            console.log('Sending request : ', `${this.baseUrl}/${entity}?${qs.stringify(searchParams)}`);
            return this.createFetch()(`${this.baseUrl}/${entity}?${qs.stringify(searchParams)}`)
        },

        /**
         * Search for the one Entity
         *
         * @param {string} entity The plural form of the entity name
         * @param {Strapi4SearchParams} searchParams
         * @param {number} [index] default 0
         *
         * @return {Promise<Strapi4ApiResponse>}
         */
        async searchOne (entity, searchParams, index = 0) {
            return this.find(entity, searchParams)
                .then(({data}) => {
                    if (data && data[index]) {
                        return data[index];
                    }
                    return null;
                })
            ;
        },

        /**
         * Return the total elements of a search
         *
         * @param {string} entity
         * @param {Strapi4SearchParams} searchParams
         *
         * @return {Promise<number>}
         */
        async count (entity, searchParams) {
            const pagination = searchParams.pagination || { withCount: true };
            const data = await this.find(entity, {...searchParams, pagination: {...pagination, withCount: true}});
            return this.find(entity, {...searchParams, pagination: {...pagination, withCount: true}})
                .then(({data}) => data.pagination.total)
            ;
        },

        async findOne (entity, id, queryParams = {}) {
            return this.createFetch()(`${this.baseUrl}/${entity}/${id}?${qs.stringify(queryParams)}`)
                .then(({data}) => data);
        },

        async create (entity, attributes, queryParams = {}) {
            return this.createFetch()(`${this.baseUrl}/${entity}?${qs.stringify(queryParams)}`, {method: 'POST', body: {data: attributes}})
        },

        /**
         *
         * @param entity
         * @param id
         * @param attributes
         * @param {Strapi4QueryParams} queryParams
         * @returns {Promise<*>}
         */
        async update (entity, id, attributes, queryParams= {}) {
            return this.createFetch()(`${this.baseUrl}/${entity}/${id}?${qs.stringify(queryParams)}`, {method: 'PUT', body: {data: attributes}})
                .then(({data}) => data);
        },

        async delete (entity, id) {
            return (this.createFetch()(`${this.baseUrl}/${entity}/${id}`, {method: 'DELETE'}))
        },

        /** @callback APILoginMethod
         * Set the token in localStorage
         * @param {object} authDetails
         * @param {string} authDetails.identifier
         * @param {string} authDetails.password
         * @returns {Promise<object>}
         */
        async login ({identifier, password}) {
            return (this.createFetch({withAuthHeaders: false})(
                `${this.baseUrl}/auth/local`,
                {
                    body: {
                        identifier,
                        password
                    },
                    method: 'POST'
                }
            ));
        },

        /** @callback APILoginMethod
         * Set the token in localStorage
         * @param {object} authDetails
         * @param {string} authDetails.email
         * @returns {Promise<object>}
         */
        async forgotPassword ({email}) {
            return (this.createFetch({withAuthHeaders: false})(
                `${this.baseUrl}/auth/forgot-password`,
                {
                    method: 'POST',
                    body: {email}
                }
            ));
        },

        /**
         * Call the server to send a email with a uniq link
         * @param {string} code
         * @param {string} password
         * @param {string} passwordConfirmation
         * @returns {Promise<true>}
         */
        async changeForgottenPassword ({code, password, passwordConfirmation}) {
            return (this.createFetch({withAuthHeaders: false})(
                `${this.baseUrl}/auth/reset-password`,
                {
                    method: 'POST',
                    body: {
                        code,
                        password,
                        passwordConfirmation
                    }
                }
            ));
        },

        /**
         * @typedef RegistrationResponse
         * @property {string} jwt the token to authenticate the user on request
         * @property {User|object|*} user the token to authenticate the user on request
         */

        /**
         * Register the user
         * @param {object} registrationDetails
         * @param {string} registrationDetails.username
         * @param {string} registrationDetails.email
         * @param {string} registrationDetails.password
         * @returns {Promise<RegistrationResponse>}
         */
        async register(registrationDetails) {
            return (this.createFetch({withAuthHeaders: false})(`${this.baseUrl}/auth/local/register`, {method: 'POST', body: registrationDetails}));
        },

        async me () {
            return (this.createFetch()(`${this.baseUrl}/users/me`));
        },

        /**
         * @deprecated use getMediaUrl() from strapi.js instead
         * @param url
         * @returns {string|*}
         */
        getMediaUrl(url) {
            return getMediaUrl(url);
        }
    };
}


/** @callback ApiCountMethod
 * @param {Object} params
 * @param {Object} params.search the search object filters
 * @return {Promise<number>}
 */

/** @callback ApiInsertMethod
 */
/** @callback ApiUpdateMethod
 */
/** @callback ApiUpsertMethod
 */
/** @callback ApiDeleteMethod
 * @param {number|string} id The identifier to delete
 * @return boolean Whether the entity has been deleted or not
 */

/**
 * @template E
 * @callback EntityMapperCallback A callback to pass all objects returned from the API
 * @param {Object} object
 * @returns {E}
 */



/**
 * @template M
 */
class API {
    /**
     * @property {EntityMapperCallback} entityMapper
     */
    entityMapper;

    /**
     * the pluralized entity name
     * @property {string} entity
     */
    entity;

    /**
     * @propert {Strapi4SearchParams.populate|string|Object|'*'} populateConfig See https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields
     */
    populateConfig;

    /**
     * @constructor
     * @param {string} entity the name (pluralized) of the entity
     * @param {EntityMapperCallback} entityMapper
     * @param {Strapi4SearchParams.populate|string|Object|'*'} [populateConfig] See https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields
     */
    constructor(entity, entityMapper = (obj) => obj, populateConfig = null) {
        this.entity = entity;
        this.entityMapper = entityMapper;
        this.populateConfig = populateConfig;
        this.strapi = useStrapi();
    }
}

/**
 * @typedef ApiFindMethod
 * @function ApiFindMethod
 * @async
 * @param {Strapi4SearchParams} params
 * @return {Promise<T[]>}
 */
/**
 * @typedef ApiSearchMethod
 * @function ApiSearchMethod
 * Alias of find() but returns metadata about the search
 * @return {Strapi4ApiResponse}
 */
/**
 * @typedef  ApiFindOneMethod
 * @function ApiFindOneMethod
 * @param {*} params
 * @return {Promise<T>}
 */
/**
 * @typedef  ApiSearchOneMethod
 * @function ApiSearchOneMethod
 * @param {strapi~Strapi4SearchParams} searchParams
 * @param {number} index the index to get from the results of the search
 * @return {Promise<T|null>}
 */

/**
 * A collection of function to process entity
 * @template T
 * @extends API
 */
class CollectionEntityApi extends API {


    /**
     * ApiFindMethod
     * @async
     * @param {Strapi4SearchParams} params
     * @return {Promise<T[]>}     */
    async find({...params}) {
        return this.strapi.find(this.entity, {...params, populate: params.populate || this.populateConfig })
            .then(({data}) => data.map(o => this.entityMapper(o)))
        ;
    }

    /** @type {ApiSearchMethod}
     */
    async search({...params}) {
        return this.strapi.find(this.entity, {...params, populate: params.populate || this.populateConfig })
            .then(data => ({
                ...data,
                data: data.data.map(o => {
                    return this.entityMapper(o)
                })
            }))
        ;
    }

    /**
     * @type {ApiFindOneMethod}
     */
    async findOne({id}) {
        return this.strapi.findOne(this.entity, id, {populate: this.populateConfig})
            .then(data => this.entityMapper(data))
        ;
    }

    /**
     * @type ApiSearchOneMethod
     */
    async searchOne (searchParams, index = 0) {
        return this.strapi.searchOne(this.entity, {...searchParams, populate: searchParams.populate || this.populateConfig }, index)
            .then(res => res ? this.entityMapper(res) : null)
        ;
    }
    /**
     * @function ApiUpdateMethod
     * @param {T|object} properties
     * @param {string|number} properties.id
     * @param {any} properties.attributes
     * @return {Promise<T>}
     */
    async update({id, ...attributes}) {
        return this.strapi.update(this.entity, id, attributes, { populate: this.populateConfig})
            .then(data => this.entityMapper(data))
        ;
    }

    /**
     * @function ApiCountMethod
     * @param {object} params
     * @param {*} params.search the search object filters
     * @return {Promise<number>}
     */
    async count({search}) {
        return this.strapi.count(this.entity, search);
    }

    /**
     * @function ApiInsertMethod
     * @param {T|object} data
     * @param {*} data.id
     * @param {*} data.attributes
     * @return {Promise<T>} the created object
     */
    async create ({id, ...attributes}) {
        // The id is destructured to ensure it is not sent to the api
        return this.strapi.create(this.entity, attributes, { populate: this.populateConfig})
            .then(({data, meta}) => this.entityMapper(data));
    }

    /**
     * @deprecated use save() method instead
     * @param id
     * @param attributes
     * @return {Promise<T>}
     */
    async upsert({id, ...attributes}) {
        return this.save({id, ...attributes});
    }

    /**
     * Update or create the entity
     * @param {T|object} data
     * @param {*} data.id
     * @param {*} data.attributes
     * @return {Promise<T>}
     */
    async save({id, ...attributes}) {
        return !id ? this.create(attributes)
            : this.update({id, ...attributes});
    }

    /**
     * @function ApiDeleteMethod
     * @param {number|string} id
     * @return {Promise<T>} the deleted object
     */
    async delete (id) {
        return this.strapi.delete(this.entity, id);
    }
}

/**
 * A collection of function to process sluggable entity
 * @extends CollectionEntityApi
 *
 */
class SluggableEntityApi extends CollectionEntityApi {
    /** @type ApiFindOneMethod */
    async findOne({id, slug}) {
        if (id) {
            return super.findOne({id});
        }
        else if (slug) {
            return this.searchOne({filters: {slug: {$eq: slug}}});
        }
    }
}

/**
 * A collection of function to process strapi single type entity
 * @extends API
 */
export class SingleTypeEntityApi extends API {
    async fetch() {
        return this.strapi.createFetch()(`${this.strapi.baseUrl}/${this.entity}`, { params: {populate: this.populateConfig} })
            .then(({data}) => (
                this.entityMapper({...data.attributes, id: data.id})
             ));
    }

    /**
     * Update or create the entity
     *
     * @param {T|object} attributes
     * @return {Promise<T>}
     */
    async createOrUpdate (attributes) {
        const {data} = await this.strapi.createFetch()(`${this.strapi.baseUrl}/${this.entity}`, {
            method: 'PUT',
            body: attributes,
            params: { populate: this.populateConfig }
        })
        return this.entityMapper({...data.attributes, id: data.id});
    }

    async delete (entity) {
        return await this.strapi.createFetch()(`${this.strapi.baseUrl}/${entity}`, {method: 'DELETE'})
    }
}

/**
 * Create a CollectionEntityApi instance
 *
 * @function
 *
 * @template U - An entity object
 * @constructs CollectionEntityApi
 * @param {string} entity
 * @param {function(object): U} entityMapper
 * @param {Strapi4SearchParams.populate|string|Object|'*'} [populateConfig] See https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields
 * @return CollectionEntityApi<U|*>
 */
export function useEntityApi(entity, entityMapper, populateConfig = null) {
    return new CollectionEntityApi(entity, entityMapper, populateConfig);
}


/**
 * Create a SluggableEntityApi instance
 *
 * @function
 * @template {object} U - An entity object
 * @param {string} entity
 * @param {function(object): U} entityMapper
 * @param {Strapi4SearchParams.populate|string|Object|'*'} [populateConfig] See https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields
 * @return SluggableEntityApi<U|*>
 */
export function useSluggableEntityApi(entity, entityMapper, populateConfig = null) {
    return new SluggableEntityApi(entity, entityMapper, populateConfig);
}

/**
 * Create a SingleTypeEntityApi instance
 *
 * @template U - An entity object
 * @param {string} entity
 * @param {function(object): U} entityMapper
 * @param {Strapi4SearchParams.populate|string|Object|'*'} [populateConfig] See https://docs.strapi.io/developer-docs/latest/developer-resources/database-apis-reference/rest/populating-fields.html#relation-media-fields
 * @return SingleTypeEntityApi<U|*>
 */
export function useSingleTypeEntityApi(entity, entityMapper, populateConfig = null) {
    return new SingleTypeEntityApi(entity, entityMapper, populateConfig);
}

